/*================================================================================
	Item Name: vep - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

//defaults
$theme-green-bg: #2eb67d !default;
$theme-grey-bg: #f8f8f8 !default;

::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
    height: 5px;
}

/* Set the thumb color and appearance */
::-webkit-scrollbar-thumb {
    background-color: #89888d;
    border-radius: 10px;
}
.pr-50 {
    padding-right: 0.5rem;
}
.pr-1 {
    padding-right: 1rem;
}
.mr-1 {
    margin-right: 1rem;
}
.greenBackground {
    background-color: $theme-green-bg;
}
.greyBackground {
    background-color: $theme-grey-bg !important;
}
.whiteBackground {
    background-color: white !important;
}
.ql-container.ql-snow {
    height: 150px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.auth_image {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: white;
}
#react-doc-viewer {
    height: 100%;
}
.mr-15-per {
    margin: 15% !important;
}
.backgroundImage3 {
    background-image: url('../images/pages/auth/login_1.png');
    background-size: cover;
}
.px-2rem {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}
.px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.px-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
}
.my-dropdown-menu {
    overflow: visible;
    position: absolute;
    left: -120px !important;
    top: 0px;
}
.auth_image_card {
    border: 2px solid black;
    border-radius: 10px;
    width: 350px;
}

.auth_image_border_radius {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.auth_image_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: lowercase;
    text-align: initial;
    color: #000000;
}

.auth_image_text_sub {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    color: #5e5873;
    text-align: initial;
    margin-top: 10px;
    margin-left: 10px;
}

.auth_image_icon_div {
    position: relative;
}
.auth_image_icon {
    position: absolute;
    top: -50px;
    right: -50px;
}
.auth_card_image_icon {
    width: 125px;
    padding-left: 2rem;
    padding-top: 2rem;
}
.mx-o_imp {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.auth_card_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #2eb67d;
    padding-left: 2rem;
    padding-top: 2rem;
}
.bordered_card {
    border: 1px solid black;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    background-color: white;
    min-width: 400px;
    margin-bottom: 0px;
}
.bordered_card_with_header {
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    background-color: white;
    margin-bottom: 0px;
    height: 100%;
}
.bold {
    font-weight: 800;
}
.pr-75 {
    padding-right: 0.75rem;
}
.pr-50 {
    padding-right: 0.5rem;
}
.auth_display_flex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.auth_or {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    letter-spacing: 0.4px;
    color: #5e5873;
}

.success_btn {
    background-color: #2eb67d !important;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 5px;
    min-width: 140px;
    max-width: 250px;
    border-width: 1px 1px 3px 3px;
    height: 54px;
}
.success_btn:active {
    background-color: #2eb67d !important;
}
.success_btn:focus {
    background-color: #2eb67d !important;
}
.success_btn_2 {
    background-color: #2eb67d !important;
    border-radius: 5px;
    min-width: 140px;
    max-width: 250px;
    height: 54px;
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2) inset;
    border-color: #2eb67d !important;
}
.success_btn_2:active {
    background-color: #2eb67d !important;
}
.success_btn_2:focus {
    background-color: #2eb67d !important;
}

.success_btn_sm {
    background: #2eb67d !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
}
.success_btn_sm:active {
    background-color: #2eb67d !important;
}

.success_btn_sm:focus {
    background-color: #2eb67d !important;
}
.success_btn_sm:hover {
    box-shadow: 0 8px 8px -8px #a9a5a5 !important;
}
.success_btn_sm_2 {
    background: #2eb67d !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2) inset;
    border-color: #2eb67d !important;
}
.success_btn_sm_2:active {
    background-color: #2eb67d !important;
}

.success_btn_sm_2:focus {
    background-color: #2eb67d !important;
}
.success_btn_sm_2:hover {
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2) inset !important;
}
.info_btn {
    background: #e6f6ef !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 5px;
    min-width: 140px;
    max-width: 250px;
    color: #5e5873 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
}
.info_btn:active {
    background-color: #e6f6ef !important;
}

.info_btn:focus {
    background-color: #e6f6ef !important;
}

.info_btn_sm {
    background: #e6f6ef !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 5px;
    color: #5e5873 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
}
.info_btn_sm:active {
    background-color: #e6f6ef !important;
}

.info_btn_sm:focus {
    background-color: #e6f6ef !important;
}
.info_btn_sm:hover {
    box-shadow: 0 8px 8px -8px #a9a5a5 !important;
}
.auth_card_footer {
    text-align: center;
    padding-top: 2rem;
    border-top: 1px solid black;
    padding-bottom: 2rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #5e5873;
}
.auth_card_link {
    color: #7367f0;
    font-weight: 800;
}
.auth_card_link_2 {
    color: #7367f0;
    font-weight: 400;
}
.auth_icon_Inline {
    align-items: center;
    display: inline-flex;
    justify-content: center;
}
.flex_center {
    justify-content: center;
    display: flex;
    align-items: center;
}

.image_icon_v1 {
    position: absolute;
    top: -60px;
    right: -20px;
}
.image_icon_v2 {
    position: absolute;
    top: -55px;
    left: -20px;
}
.image_icon_v3 {
    position: absolute;
    top: -60px;
    left: -20px;
}
.image_icon_v4 {
    position: absolute;
    top: -55px;
    right: -20px;
}
.image_v1_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #000000;
}
.black_btn {
    background: #000000 !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 20px;
}
.black_btn:active {
    background-color: #000000 !important;
}

.black_btn:focus {
    background-color: #000000 !important;
}
.black_btn_sm {
    background: #000000 !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 5px;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
}
.black_btn_sm:active {
    background-color: #000000 !important;
}

.black_btn_sm:focus {
    background-color: #000000 !important;
}
.pl-2 {
    padding-left: 2rem !important;
}
.pr-2 {
    padding-right: 2rem !important;
}
.plt-1 {
    padding-left: 1rem;
    padding-top: 1rem;
}

.email_verify_grey_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #5e5873;
}
.black {
    color: black;
}

.email_verify_sub_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5e5873;
}
.email_verify_text_area {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
    max-width: 420px;
}

.email_verify_email {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}

.email_verify_label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6e6b7b;
}

.resendBtn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7367f0;
    cursor: pointer;
}

.pass_verify_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
}
.pass_verify_label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #c4c4c4;
}

.pass_verify_sub_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}

.pass_verify_title_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5e5873;
}
.pass_verify_p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}
.pass_verify_p_green {
    color: #2eb67d;
    font-weight: 800;
}
.pass_verify_check_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6e6b7b;
}

.profile_creation_text_1 {
    font-family: 'Montserrat';
    margin-top: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5e5873;
}
.profile_creation_text_2 {
    margin-top: 0.5rem;
    max-width: 550px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.dotted_border {
    border-bottom: 1px dashed #c4c4c4;
    margin-top: 10px;
    margin-bottom: 10px;
}
.file_uploader_card {
    border: 1px dashed #2eb67d;
    padding: 1rem 1.5rem !important;
    // max-width: 400px;
}
.file_uploader_div1 {
    cursor: pointer;
}
.file_uploader_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2eb67d;
    margin-left: 10px;
}
.profile_creation_nav {
    background: #000 !important;
    border-bottom: 1px solid #000000;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 1rem;
}

.profile_creation_app_content {
    padding-top: 4rem;
    padding-bottom: 1rem;
}

.header_direction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_direction_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 0px;
}

.padding_2 {
    padding: 2rem;
}

.next_page_card {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    margin-bottom: 0px;
    border: 1px solid black;
}
.next_page_card_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
}
.next_page_card_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}

.next_page_card_text_3 {
    color: #c4c4c4;
}

.min_width_600 {
    min-width: 600px !important;
}
.max_width_1000 {
    max-width: 1000px !important;
}
.radio_flex {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-top: 0.7rem;
}
.dotted_border_2 {
    border-bottom: 3px dotted grey;
    margin-top: 2rem;
}
.radio_flex_2 {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 0.7rem;
}
.radio_flex_basic_details {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin-top: 0.7rem;
}
.display_flex_center {
    display: flex;
    align-content: center;
    align-items: center;
}
.pricing_text_1 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pricing_text_2 {
    color: #c4c4c4;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.display_flex_right {
    display: flex;
    align-content: center;
    align-items: center;
}
.display_flex_center_justify {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.cursor_pointer {
    cursor: pointer;
}
.mb-half {
    margin-bottom: 0.5rem;
}
.mb-2_5 {
    margin-bottom: 2.5rem !important;
}
.profile_file_uploader_div {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    background: #d9d9d9;
    border: 0px;
}
.padding_2_1btm {
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
}
.display_flex_reverse {
    display: flex;
    flex-direction: row-reverse;
}
.custom_border_header {
    border-bottom: 1px solid black;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.profile_file_uploader_image {
    width: 150px;
    height: 150px;
    border-radius: 50% !important;
    cursor: pointer;
}

.profile_flex_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
}
@media only screen and (max-width: 768px) {
    .profile_flex_column {
        align-items: inherit !important;
    }
}
.error-message {
    color: red;
}

.display_flex_center_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}
.single_talent_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}
.profile_creation_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
}

.sectionHeader_div_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
}
.sectionHeader_div_flex_3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}
.sectionHeader_div_flex_2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #c4c4c4;
}

.sectionHeader_div_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    color: #000000;
    padding-left: 5px;
}
.sectionModalDiv {
    padding-bottom: 3rem !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.sectionHeader_div_title_3 {
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    padding-left: 5px;
}
.pl-0 {
    padding-left: 0px !important;
}
.sectionHeader_div_title_2 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    padding-left: 10px;
}
.display_flex_space_between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.display_flex_space_between_start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.display_flex_start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.display_flex_space_between_2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.display_flex_align_center {
    display: flex;
    align-items: center;
}
.display_flex_center_column {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
}
.search_display_flex {
    display: flex;
    border-left: 1px solid #ebebee;
    // justify-content: center;
}
.social_link_element_flex {
    background: #e6f6ef;
    border-radius: 6px;
    padding: 1rem 1.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.hourly_charge_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.hourly_charge_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #6e6b7b;
    margin-top: 1rem;
}

.hourly_charge_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #c4c4c4;
}

.hourly_charge_type {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-top: 2.5rem !important;
    padding-left: 10px;
}

.single_qual_flex {
    display: flex;
    margin-bottom: 1rem;
}

.single_qual_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.single_qual_text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.single_qual_text3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.single_qual_text4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.single_qual_edit {
    width: 70px;
    padding-left: 5px;
}

.single_qual_checkbox {
    display: flex;
    align-items: center;
}

.single_qual_text5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #6e6b7b;
    margin-left: 5px;
    margin-bottom: 0px;
}

.single_exp_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-bottom: 0.5rem;
}
.single_exp_text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.single_exp_flex1 {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    max-width: 480px;
    margin-bottom: 1rem;
}
.sectionHeader_3_div {
    background: #2eb67d;
    border-radius: 5px 5px 0px 0px;
    padding: 2rem !important;
    margin-bottom: 0px !important;
}
.single_project_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7367f0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.single_project_text2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5e5873;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
}
.single_project_text3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 0.3rem;
}

.single_skill_flex_outer {
    display: flex;
    flex-wrap: wrap;
}

.single_skill_flex_outer > * {
    width: calc(50% - 10px);
    margin: 5px;
}

.single_skill_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: 160px !important;
}

.single_skill_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
    cursor: pointer;
}

.navbarButton {
    background: #e6f6ef;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    height: 50px;

    display: flex;
    justify-content: space-between;
}

@media (min-width: 576px) {
    .navbarButton {
        width: 210px;
    }
}
.nav_btn_text_1 {
    color: #5e5873;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 15px;
}

.nav_btn_text_2 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 15px;
}

.nav_btn_arrow {
    width: 12px;
    box-shadow: none !important;
}

.horizon2_flex {
    padding: 7rem 2rem 2rem 2rem;
    background: #e6f6ef;
    position: relative;
    transition: 300ms ease all;
    backface-visibility: hidden;
    min-height: calc(100% - 3.35rem);
    overflow-x: auto;
}

@media (min-width: 768px) {
    .horizon2_flex {
        padding: 7rem 3rem 3rem 3rem;
    }
}

.horizon2_flex_nav {
    display: flex !important;
    margin-top: 30px !important;
    margin-left: 3rem !important;
    margin-right: 3rem !important;
    width: auto !important;
}

.horizon2_active_nav {
    background: #6eb77d !important;
    box-shadow: 0 0 6px 1px #6eb77d99 !important;
    border-radius: 4px;
    color: #fff;
}

.horizon2_nav_header {
    padding: 3px 5px;
    margin: 0 5px;
    color: #fff;
    font-size: 16px;
    // border-bottom: 2px solid white;
}
.horizon2_nav_header_active {
    padding: 3px 5px;
    color: #fff;
    margin: 0 5px;
    font-size: 16px;
    border-bottom: 2px solid white;
}
.horizon2_nav_header:hover {
    color: #fff !important;
    // border-bottom: 2px solid white;
}
.horizon2_nav_header_active:hover {
    color: #fff !important;
    // border-bottom: 2px solid white;
}
.display_flex_space_between_center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.display_flex_space_evenly_center {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.display_flex_start_center {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.display_flex_end_column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.pl-1 {
    padding-left: 1rem;
}
.pl-2 {
    padding-left: 2rem;
}
.outline_btn {
    border: 1px solid #c4c4c4 !important;
}
.outline_btn2 {
    border-radius: 5px;
    border: 1px solid #a9a5a5 !important;
    padding: 5px !important;
}
.border_color_grey_2 {
    border-radius: 5px;
    border: 1px solid #a9a5a5 !important;
    padding: 5px !important;
}
.search_sec_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.display_flex_wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.selected_search_div {
    background: rgba(115, 103, 240, 0.12);
    border-radius: 4px;
    padding: 0.5rem;
    margin: 0.25rem;
}
.selected_search_delete {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
}
.border_left_1px {
    border-left: 1px solid #ebebee;
}
.selected_search_items {
    padding: 10px;
}

@media (min-width: 768px) {
    .selected_search_items {
        border-left: 1px solid #ebebee;
    }
}

.card_with_header {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #6e6b7b;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #c4c4c4;
}
.border_bottom {
    border-bottom: 1px solid #c4c4c4;
}
.single_talent_block_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    margin-left: 5px;
}
.single_talent_block_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    margin-left: 5px;
    color: #6e6b7b;
}

.talent_details_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
}
.talent_details_section {
    padding-top: 8px;
}

.talent_header_nav_active {
    background: #2eb67d;
    // border: 1px solid #000000;
    border-radius: 10px;
    min-width: 140px;
    padding: 0.5rem 1rem;
    color: white;
    margin: 0 0.25rem;
}
.talent_header_nav {
    background: #ffffff;
    // border: 1px solid #000000;
    border-radius: 10px;
    min-width: 140px;
    padding: 0.5rem 1rem;
    color: #c4c4c4;
    margin: 0 0.25rem;
}

.talent_details_header_flex {
    max-height: 400px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    scroll-behavior: smooth;
    transition: transform 0.3s ease-in-out;
}
.talent_details_header_flex::-webkit-scrollbar {
    display: none;
}

.expert_btn {
    border: 1px solid #365ff0;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    color: white;
    background-color: #365ff0;
    cursor: pointer;
    display: flex;
}

.talent_linkedIn_btn {
    border: 1px solid #365ff0;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    color: #365ff0;
    cursor: pointer;
    display: flex;
}
.talent_sourceURL_btn {
    border: 1px solid #6e6b7b;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    color: #6e6b7b;
    // cursor: pointer;
    display: flex;
}
.basic_details_header {
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
}
.basic_details_header_2 {
    color: #6e6b7b;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 191.667% */
    letter-spacing: 0.6px;
    text-transform: uppercase;
}
.basic_details_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}
.basic_details_text_3 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 164.286% */
}
.ml-1 {
    margin-left: 1rem;
}
.ml-2 {
    margin-left: 2rem;
}

.search_header {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #6e6b7b;
}
.search_nav_item {
    padding-left: 2px !important;
    padding-right: 2px !important;
    margin-right: 15px !important;
    font-size: 16px !important;
}
.nav_item_tab {
    padding-left: 2px !important;
    padding-right: 2px !important;
    margin-right: 15px !important;
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.search_nav {
    border-bottom: 1px solid !important;
    border-radius: 0px !important;
}
.info_btn {
    background: #f8f8f8 !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 5px;
    min-width: 140px;
    max-width: 250px;
    color: #5e5873 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
}
.grey_btn:active {
    background-color: #f8f8f8 !important;
}

.grey_btn:focus {
    background-color: #f8f8f8 !important;
}

.grey_btn_sm {
    background: #f8f8f8 !important;
    border-width: 1px 1px 3px 3px;
    border-style: solid;
    border-color: #000000 !important;
    border-radius: 5px;
    color: #5e5873 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
}
.grey_btn_sm:active {
    background-color: #f8f8f8 !important;
}

.grey_btn_sm:focus {
    background-color: #f8f8f8 !important;
}
.success_btn_sm:hover {
    box-shadow: 0 8px 8px -8px #a9a5a5 !important;
}
.sectionHeader_sub_title {
    padding-top: 0.25rem;
    padding-left: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #c4c4c4;
}
.single_client_user_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
}
.flex_start {
    align-items: flex-start !important;
}
.single_project_details_flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
}
.single_client_flex_text_1 {
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #5e5873;
}
.single_client_flex_text_2 {
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #000000;
}
.single_client_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.single_new_client_user_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.single_client_color {
    color: #7367f0 !important;
    background: rgba(115, 103, 240, 0.12) !important;
    margin-right: 10px;
}
.hourly_charge_type {
    width: 105px;
    margin-top: 2.2rem !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding-left: 10px;
    padding-right: 10px;
    height: 38px;
    background: #2eb67d;
    border-radius: 10px;
    margin-left: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hourly_charge_type_2 {
    width: 105px;
    margin-top: 1.6rem !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding-left: 10px;
    padding-right: 10px;
    height: 38px;
    background: #2eb67d;
    border-radius: 10px;
    margin-left: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.client_setUp_admin_div {
    background: #f8f8f8;
    padding: 10px;
    border-radius: 8px;
    min-height: 45px;
}
.client_setUp_admin_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #365ff0;
}
.client_setUp_admin_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6e6b7b;
}
.client_setUp_admin_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6e6b7b;
}
.dashboard_box_card {
    background: #bcf8d0;
    border: 1px solid #000000;
    border-radius: 10px;
    padding-top: 20px;
    overflow: hidden;
    height: 100%;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.dashboard_box_up_section {
    padding-bottom: 20px;
}
.dashboard_box_left_section {
    padding-left: 20px;
}
.dashboard_box_right_section {
    padding-right: 20px;
}
.dashboard_box_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #000000;
    padding-bottom: 20px;
}
.dashboard_box_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #2eb67d;
    padding-bottom: 10px;
    padding-left: 10px;
}
.header_arrow {
    border-radius: 50% !important;
    background: black;
    color: white;
    padding-top: 5px;
    padding-right: 7px;
    padding-bottom: 5px;
    padding-left: 7px;
    margin-right: 5px;
    cursor: pointer;
}
.repeat_fin_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
}
.repeat_fin_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}
.repeat_fin_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #7367f0;
}
.view_client_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.view_client_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #6e6b7b;
}
.view_single_project_card {
    background: #f8f8f8;
    border-radius: 6px;
    padding: 15px 20px;
    margin-bottom: 1rem;
}

.view_single_project_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #365ff0;
    cursor: pointer;
}
.view_single_project_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.overflow_X_scroll {
    overflow-x: auto;
    overflow-y: hidden;
}
.load_more_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #7367f0;
    cursor: pointer;
}
.basic_details_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
}
.user_note_box {
    background: #bcf8d0;
    border-radius: 10px;
    padding: 10px 20px;
}
.expanded_section {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.section_show {
    max-height: 1000px;
}

.section_hide {
    max-height: 0;
    overflow: hidden;
}
.single_skill_flex_outer {
    display: flex;
    flex-wrap: wrap;
}

.single_skill_flex_outer > * {
    width: calc(50% - 10px);
    margin: 5px;
}

.single_skill_flex_2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #e6f6ef;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: 160px;
}
.single_skill_flex_pipeline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    width: 110px;
    padding: 0.5rem 1rem;
}
.single_skill_flex_pipeline-estimate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ceec19 !important;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: 150px !important;
    margin-left: 0px !important;
}
.single_skill_flex_pipeline-finalAmount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #2eb67d !important;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: 150px !important;
    margin-left: 0px !important;
}

.single_skill_text1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}
.single_skill_text_2 {
    color: #6e6b7b;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 175% */
}
.search_button {
    background-color: #f8f8f8 !important;
    color: green !important;
}
.search_button:active {
    background-color: #f8f8f8 !important;
}

.search_button:focus {
    background-color: #f8f8f8 !important;
}
.single_project_question_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.single_project_question_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.project_details_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
    width: 300px;
}
.project_details_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
    width: 360px;
}
.project_details_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
}
.single_log_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}
.single_log_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5e5873;
}
.single_log_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2eb67d;
    margin-left: 3.3rem;
    margin-bottom: 1rem;
    cursor: pointer;
}
.activity_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}
.view_project_nav_div {
    border-top: 1px solid #a6a1ad;
}
.view_project_header_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #5e5873;
}
.view_project_header_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #5e5873;
}
.project_info_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.project_info_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7367f0;
    cursor: pointer;
}
.single_question_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #6e6b7b;
}
.view_financial_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.view_financial_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6e6b7b;
}
.view_project_header_text_4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: white;
}
.pipeline_long_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #6e6b7b;
}
.hourly_right_action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.onboard_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #a9a5a5;
}
.onboard_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
}
.meeting_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #7367f0;
    cursor: pointer;
}
.meeting_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}
.meeting_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.estimate_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #a9a5a5;
}
.offer_info_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #6e6b7b;
}
.offer_info_text_2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0d0d0d;
}
.single_question_text_4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #6e6b7b;
}
.single_project_text4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.projects_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    width: 350px;
}
.single_talent_block_text_3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #5e5873;
}
.single_talent_block_text_4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #b9b9c3;
}
.project_filter_text_1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.single_project_filter_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: 300px;
}
.found_tal_text_1 {
    cursor: pointer;
    border-radius: 5px;
    border-top: 1px solid #2eb67d;
    border-right: 1px solid #2eb67d;
    border-bottom: 3px solid #2eb67d;
    border-left: 3px solid #2eb67d;
    background: #e6f6ef;
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.2) inset;
    margin-right: 5px;
    padding: 5px 10px;
    color: #2eb67d;
    font-family: Montserrat;
    font-size: 16px;
}
.talent_pill_1 {
    background-color: #ceec19 !important;
    color: black;
}
.talent_pill_2 {
    background-color: #365ff0 !important;
    color: white;
}
.talent_pill_3 {
    background-color: #c4c4c4 !important;
    color: white;
}
.onboard_text_4 {
    color: #c4c4c4;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.talent_flex {
    min-height: 60vh;
    overflow-x: hidden;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.interested_in_section {
    border-radius: 10px;
    padding: 10px 20px;
    max-width: 400px;
    border-radius: 10px;
    background: #e1ffcf;
}
.onboard_text_5 {
    cursor: pointer;
    color: #7367f0;
    text-transform: uppercase;
    text-decoration: underline;
}

.onboard_text_6 {
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 10px;
}
.user_perks_div {
    color: #6e6b7b;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: 130px;
    border: 1px solid #6e6b7b;
    padding: 0.25rem;
    justify-content: center;
    cursor: pointer;
}
.custom_check_text_1 {
    color: var(--1-theme-color-body-text, #6e6b7b);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body / Paragraph */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
}
.ip_style {
    cursor: pointer;
    color: #365ff0;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
}
.register_text_4_link {
    color: #365ff0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-decoration-line: underline;
}
.reset_filter {
    color: #6e6b7b;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.div_border_header {
    margin-left: -15px !important;
    margin-right: -15px !important;
    border-bottom: 1px solid #2eb67d !important;
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
}
.bulk_btn_text {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
}
.bulk_btn_text_2 {
    color: #6e6b7b;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.outline_btn_2 {
    color: #a9a5a5;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 5px;
}
@media (max-width: 700px) {
    .hide_700 {
        display: none;
    }
}
.marked_field {
    color: #2eb67d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.loading_footer_text_1 {
    color: #2eb67d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}
.loading_footer_text_2 {
    color: #2eb67d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}
